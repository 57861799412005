import React from 'react';
import pdfIcon from 'images/icon/pdf-icon.svg';
import downloadIcon from 'images/icon/download-orange-ic.svg';
import { useTranslation } from 'react-i18next';

const SectionNominationRemuneration = () => {
  const { t } = useTranslation('about-us');
  return (
    <div className="min-h-[60vh] py-3 text-justify">
      <div>
        <div className="border-b py-3">
          <h2 className="text-base md:text-2xl font-medium">
            {t('title komite-nominasi')}
          </h2>
        </div>
        <p className="text-sm md:text-base font-[200] py-3 md:py-5">
          {t('desc komite-nominasi')}
        </p>
        <div className="border-b py-3">
          <h2 className="text-base md:text-2xl font-medium">
            {t('title komite-nominasi pedoman')}
          </h2>
        </div>
        <div
          className="flex justify-between py-3 md:py-5 px-0 md:px-3 cursor-pointer hover:underline"
          onClick={() =>
            window.open(
              'https://finfolk.ap-south-1.linodeobjects.com/003-PD-NR-MGU-II-2023.pdf',
            )
          }
        >
          <div className="flex items-center gap-3">
            <img src={pdfIcon} alt="pdf-icon" className="w-4" />
            <p className="text-sm md:text-base font-[200]">
              {t('title komite-nominasi pedoman')}
            </p>
          </div>
          <img
            src={downloadIcon}
            alt="icon-download"
            className="block md:hidden"
          />
        </div>
      </div>
    </div>
  );
};

export default SectionNominationRemuneration;
