import React from 'react';
import { useTranslation } from 'react-i18next';

const SectionSupportingInstitutions = () => {
  const { t } = useTranslation('about-us');

  return (
    <div className="min-h-[60vh] py-3">
      <div>
        <div className="border-b py-3 mb-2">
          <h2 className="text-base md:text-2xl font-medium">
            {t('title lembaga-penunjang')}
          </h2>
        </div>

        <div className="py-3 md:py-5">
          <b className="text-base font-semibold">
            {t('Akuntan Publik')}
            <br />
            Kanaka Puradiredja Suhartono
          </b>
          <p className="text-sm mt-1">
            18 Office Park Tower A, 20th Floor
            <br />
            Jl. TB. Simatupang No. 18,
            <br />
            Kebagusan, Pasar Minggu, Jakarta 12520
            <br />
            Telp. (021) 22708292
          </p>
          <br />
          <b className="text-base font-semibold">
            {t('Konsultan Hukum')}
            <br />
            Irma & Solomon Law Firm
          </b>
          <p className="text-sm mt-1">
            Sequis Center Lt. 9,
            <br />
            Jl. Jend. Sudirman 71, Jakarta 12190
            <br />
            Telp. (021) 52903957
            <br />
          </p>
          <br />
          <b className="text-base font-semibold">
            {t('Kustodian')}
            <br />
            PT Kustodian Sentral Efek Indonesia (KSEI)
          </b>
          <p className="text-sm mt-1">
            Gedung Bursa Efek Indonesia (BEI)
            <br />
            Tower I Lt. 5<br />
            Jl. Jenderal Sudirman Kav. 52 – 53, Jakarta 12190
            <br />
            Telp. (021) 52991099
          </p>
          <br />
          <b className="text-base font-semibold">
            {t('Biro Administrasi Efek')}
            <br />
            PT Adimitra Jasa Korpora
          </b>
          <p className="text-sm mt-1">
            Rukan Kirana Boutique Office <br />
            Jl. Kirana Avenue III Blok F3 No. 5<br />
            Kelapa Gading, Jakarta Utara 14240
            <br />
            Telp. (021) 29745222
          </p>
        </div>
      </div>
    </div>
  );
};

export default SectionSupportingInstitutions;
