import React from 'react';
import pdfIcon from 'images/icon/pdf-icon.svg';
import downloadIcon from 'images/icon/download-orange-ic.svg';
import { useTranslation } from 'react-i18next';

const SectionArticlesOfAssociation = () => {
  const { t } = useTranslation('about-us');
  return (
    <div className="min-h-[60vh] py-3">
      <div>
        <div className="border-b py-3">
          <h2 className="text-base md:text-2xl font-medium">
            {t('title anggaran-dasar')}
          </h2>
        </div>
        <div className="py-5 px-3 ">
          <div className="flex justify-between py-3 md:py-2 cursor-pointer hover:underline">
            <div className="flex items-center gap-3">
              <img src={pdfIcon} alt="pdf-icon" className="w-4" />
              <p className="text-sm md:text-base font-[200]">
                {t('title anggaran-dasar')}
              </p>
            </div>
            <img
              src={downloadIcon}
              alt="icon-download"
              className="block md:hidden"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionArticlesOfAssociation;
