import React from 'react';
import { useTranslation } from 'react-i18next';

const SidebarNavigation = ({
  selectedItem,
  handleSelected,
  listNavSidebar,
}) => {
  const { t } = useTranslation('about-us');

  const renderNavItem = () => {
    return listNavSidebar.map((nav, idx) => {
      return (
        <p
          className={`${
            nav.id === selectedItem
              ? 'font-medium text-white nav-sidebar-active'
              : 'font-light text-[#5E5E5E]'
          } nav-sidebar-item hover:font-medium hover:text-white cursor-pointer`}
          key={idx.toString()}
          onClick={() => handleSelected(nav.id)}
        >
          {t(`title ${nav.id}`)}
        </p>
      );
    });
  };

  return (
    <div className="py-10 pl-10 pr-8 bg-folk-black rounded-lg sticky top-32 w-72 h-fit space-y-7">
      {renderNavItem()}
    </div>
  );
};

export default SidebarNavigation;
