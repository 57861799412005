import React, { useState } from 'react';
import CustomContainer from '../../CustomContainer';
import background from '../../../images/governance/banner-governance.png';
import './governance.css';
import SidebarNavigation from './components/SidebarNavigation';
import { useMediaQuery, useWindowWidth } from '../../hooks/useResponsive';
import Collapse from './components/Collapse';
import SectionBodBocCharter from './components/SectionBodBocCharter';
import SectionAuditCommittee from './components/SectionAuditCommittee';
import SectionNominationRemuneration from './components/SectionNominationRemuneration';
import SectionInternalAudit from './components/SectionInternalAudit';
import SectionCorporateSecretary from './components/SectionCorporateSecretary';
import SectionCodeOfConduct from './components/SectionCodeOfConduct';
import SectionArticlesOfAssociation from './components/SectionArticlesOfAssociation';
import { useTranslation } from 'react-i18next';
import SectionSupportingInstitutions from './components/SectionSupportingInstitutions';

const listNavSidebar = [
  {
    name: 'Piagam Dewan Komisaris Direksi',
    id: 'piagam-direksi',
  },
  {
    name: 'Komite Audit',
    id: 'komite-audit',
  },
  {
    name: 'Komite Nominasi dan Remunerasi',
    id: 'komite-nominasi',
  },
  {
    name: 'Unit Audit Internal',
    id: 'audit-internal',
  },
  {
    name: 'Sekretaris Perusahaan',
    id: 'sekretaris-perusahaan',
  },
  {
    name: 'Anggaran Dasar',
    id: 'anggaran-dasar',
  },
  {
    name: 'Kode Etik Perusahaan',
    id: 'kode-etik',
  },
  {
    name: 'Lembaga Penunjang Pasar Modal',
    id: 'lembaga-penunjang',
  },
];

const Governance = () => {
  const windowWidth = useWindowWidth();
  const mediaQuery = useMediaQuery();
  const [selectedNav, setSelectedNav] = useState('piagam-direksi');

  const { t } = useTranslation('about-us');

  return (
    <div className="min-h-screen relative">
      <img
        src={background}
        alt="background"
        className="absolute top-0 left-0 h-52 md:h-[300px] w-full object-cover"
      />
      <CustomContainer className="pt-20 h-full relative">
        <div className="h-32 md:h-56 flex items-center">
          <h1 className="text-2xl md:text-4xl md:mt-16">{t('governance')}</h1>
        </div>
        {windowWidth > mediaQuery.lg && (
          <div className="flex relative py-10 gap-14">
            <SidebarNavigation
              listNavSidebar={listNavSidebar}
              selectedItem={selectedNav}
              handleSelected={(e) => setSelectedNav(e)}
            />
            <div className="flex-1">
              {selectedNav === 'piagam-direksi' && <SectionBodBocCharter />}
              {selectedNav === 'komite-audit' && <SectionAuditCommittee />}
              {selectedNav === 'komite-nominasi' && (
                <SectionNominationRemuneration />
              )}
              {selectedNav === 'audit-internal' && <SectionInternalAudit />}
              {selectedNav === 'sekretaris-perusahaan' && (
                <SectionCorporateSecretary />
              )}
              {selectedNav === 'anggaran-dasar' && (
                <SectionArticlesOfAssociation />
              )}
              {selectedNav === 'kode-etik' && <SectionCodeOfConduct />}
              {selectedNav === 'lembaga-penunjang' && (
                <SectionSupportingInstitutions />
              )}
            </div>
          </div>
        )}
      </CustomContainer>
      <div className="block lg:hidden">
        {listNavSidebar?.map((nav, idx) => (
          <Collapse title={t(`title ${nav.id}`)} key={idx.toString()}>
            {nav.id === 'piagam-direksi' && <SectionBodBocCharter />}
            {nav.id === 'komite-audit' && <SectionAuditCommittee />}
            {nav.id === 'komite-nominasi' && <SectionNominationRemuneration />}
            {nav.id === 'audit-internal' && <SectionInternalAudit />}
            {nav.id === 'sekretaris-perusahaan' && (
              <SectionCorporateSecretary />
            )}
            {nav.id === 'anggaran-dasar' && <SectionArticlesOfAssociation />}
            {nav.id === 'kode-etik' && <SectionCodeOfConduct />}
            {nav.id === 'lembaga-penunjang' && (
              <SectionSupportingInstitutions />
            )}
          </Collapse>
        ))}
      </div>
    </div>
  );
};

export default Governance;
