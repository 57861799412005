import React from 'react';
import Pages from '../components/Pages';
import Governance from '../components/pages/Governance';
import { graphql } from 'gatsby';

const GovernancePage = () => {
  return (
    <div className="bg-black text-white">
      <Pages title="FOLK - Governance">
        <Governance />
      </Pages>
    </div>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default GovernancePage;
