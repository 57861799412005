import React from 'react';
import pdfIcon from 'images/icon/pdf-icon.svg';
import auditInternalPersonnel from 'images/governance/audit-internal.jpeg';
import downloadIcon from 'images/icon/download-orange-ic.svg';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@reach/router';

const SectionInternalAudit = () => {
  const { t } = useTranslation('about-us');
  const loc = useLocation();
  const currentPath = loc.href;
  const isEnglish = currentPath.includes('/en/');

  return (
    <div className="min-h-[60vh] py-3 text-justify">
      <div>
        <div className="border-b py-3">
          <h2 className="text-base md:text-2xl font-medium">
            {t('title audit-internal')}
          </h2>
        </div>
        <div className="py-5 px-3 flex flex-col md:flex-row">
          <div className="mr-5 h-full">
            <img
              src={auditInternalPersonnel}
              alt="audit-personnel"
              className="w-full md:w-[175px] md:max-w-[175px] object-cover rounded-md"
            />
            <div className="mt-2 md:mt-3 mb-5 md:mb-0">
              <p className="text-xl md:text-base font-medium">Emilia</p>
              <p className="font-[200] text-lg md:text-sm">
                {t('audit-internal-personnel')}
              </p>
            </div>
          </div>
          <div>{t('audit-internal-personnel-bio')}</div>
        </div>
      </div>
      <div className="mt-5 md:mt-10">
        <div className="border-b py-3">
          <h2 className="text-base md:text-2xl font-medium">
            {t('title audit-internal charter')}
          </h2>
        </div>
        <div
          className="flex justify-between py-3 md:py-5 px-0 md:px-3 cursor-pointer hover:underline"
          onClick={() =>
            window.open(
              'https://finfolk.ap-south-1.linodeobjects.com/001-PKA-MGU-II-2023 Piagam Audit Internal.pdf',
            )
          }
        >
          <div className="flex items-center gap-3">
            <img src={pdfIcon} alt="pdf-icon" className="w-4" />
            <p className="text-sm md:text-base font-[200]">
              {t('title audit-internal charter')}
            </p>
          </div>
          <img
            src={downloadIcon}
            alt="icon-download"
            className="block md:hidden"
          />
        </div>
      </div>
      <div className="mt-5 md:mt-10">
        <div className="border-b py-3">
          <h2 className="text-base md:text-2xl font-medium">
            {t('title audit-internal dasar')}
          </h2>
        </div>
        <div className="flex justify-between py-3 md:py-5 px-0 md:px-3">
          {!isEnglish ? (
            <p className="text-sm md:text-base font-[200]">
              Sebagai bagian dari penerapan <i>Good Corporate Governance</i>,
              Audit Internal memiliki peran yang sangat penting untuk mendukung
              <i>Strategic Business Objective</i> melalui kegiatan assurance dan
              consulting yang efektif dan sesuai dengan arah serta strategi
              perusahaan. Pelaksanaan fungsi audit internal yang efektif dapat
              memberikan jaminan kepada perusahaan terkait kualitas dan
              efektivitas sistem pengendalian intern, manajemen risiko serta
              sistem tata kelola untuk melindungi organisasi dan reputasi
              perusahaan. Perusahaan juga telah melakukan pengangkatan Kepala
              Unit Internal Audit yaitu Emilia berdasarkan Surat Keputusan
              Direksi No. 002/SK-MGU/II/2023 tanggal 16 Februari 2023.
              <br />
              <br />
              Ruang lingkup audit internal dituangkan dalam Piagam Audit
              Internal Perusahaan
            </p>
          ) : (
            <p className="text-sm md:text-base font-[200]">
              As part of the implementation of <i>Good Corporate Governance</i>,
              Internal Audit has a very important role to support
              <i>The Strategic Business Objective</i> through effective
              assurance and consulting activities in accordance with the
              direction and strategy of the company. Implementation of an
              effective internal audit function can provide assurance to the
              company regarding the quality and effectiveness of internal
              control systems, risk management and governance systems to protect
              the organization and company reputation. The company has also
              appointed the Head of the Internal Audit Unit, namely Emilia,
              based on the Directors Decree No. 002/SK-MGU/II/2023 dated 16
              February 2023.
              <br />
              <br />
              The scope of internal audit is stated in the Company's Internal
              Audit Charter.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionInternalAudit;
