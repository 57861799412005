import React from 'react';
import pdfIcon from 'images/icon/pdf-icon.svg';
import downloadIcon from 'images/icon/download-orange-ic.svg';
import { useTranslation } from 'react-i18next';

const SectionBodBocCharter = () => {
  const { t } = useTranslation('about-us');

  return (
    <div className="min-h-[60vh] py-3 text-justify">
      <div>
        <div className="border-b py-3">
          <h2 className="text-base md:text-2xl font-medium">
            {t('piagam dewan komisaris')}
          </h2>
        </div>
        <p className="text-sm md:text-base font-[200] py-3 md:py-5">
          {t('desc dewan-komisaris')}
        </p>

        <div
          className="flex justify-between pb-3 md:pb-5 px-0 md:px-3 cursor-pointer hover:underline"
          onClick={() =>
            window.open(
              'https://finfolk.ap-south-1.linodeobjects.com/Pedoman Dewan Komisaris.pdf',
            )
          }
        >
          <div className="flex items-center gap-3">
            <img src={pdfIcon} alt="pdf-icon" className="w-4" />
            <p className="text-sm md:text-base font-[200]">
              {t('piagam dewan komisaris')}
            </p>
          </div>
          <img
            src={downloadIcon}
            alt="icon-download"
            className="block md:hidden"
          />
        </div>
      </div>
      <div className="mt-10">
        <div className=" border-b py-3">
          <h2 className=" text-2xl font-medium">{t('piagam dewan direksi')}</h2>
        </div>
        <p className="text-sm md:text-base font-[200] py-3 md:py-5">
          {t('desc dewan-direksi')}
        </p>
        <div
          className="flex justify-between pb-3 md:pb-5 px-0 md:px-3 cursor-pointer hover:underline"
          onClick={() =>
            window.open(
              'https://finfolk.ap-south-1.linodeobjects.com/Pedoman Dewan Direksi.pdf',
            )
          }
        >
          <div className="flex items-center gap-3">
            <img src={pdfIcon} alt="pdf-icon" className="w-4" />
            <p className="text-sm md:text-base font-[200]">
              {t('piagam dewan direksi')}
            </p>
          </div>
          <img
            src={downloadIcon}
            alt="icon-download"
            className="block md:hidden"
          />
        </div>
      </div>
    </div>
  );
};

export default SectionBodBocCharter;
