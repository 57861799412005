import React, { useState } from "react";
import arrowDown from "../../../../images/icon/arrow-down-orange-ic.svg";

const Collapse = ({ title, children }) => {
  const [isShow, setIsShow] = useState(false);
  return (
    <div className="">
      <div
        onClick={() => setIsShow(!isShow)}
        className="flex items-center justify-between py-3 px-6 border-b border-gray-600"
      >
        <p className="text-sm font-[300]">{title}</p>
        <img src={arrowDown} alt="icon" />
      </div>
      <div
        className={`${
          isShow ? "block" : "hidden"
        } px-6 bg-folk-black overflow-hidden transition-all`}
      >
        {children}
      </div>
    </div>
  );
};

export default Collapse;
