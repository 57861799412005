import React from 'react';
import pdfIcon from 'images/icon/pdf-icon.svg';
import corsecPersonnel from 'images/governance/corsec.jpeg';
import downloadIcon from 'images/icon/download-orange-ic.svg';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

const SectionCorporateSecretary = () => {
  const { t } = useTranslation('about-us');

  return (
    <div className="min-h-[60vh] py-3 text-justify">
      <div>
        <div className="border-b py-3">
          <h2 className="text-base md:text-2xl font-medium">
            {t('title sekretaris-perusahaan')}
          </h2>
        </div>
        <div className="py-5 px-3 flex flex-col md:flex-row">
          <div className="mr-5 h-full">
            <img
              src={corsecPersonnel}
              alt="corsec-personnel"
              className="w-full md:w-[175px] md:max-w-[175px] object-cover rounded-md"
            />
            <div className="mt-2 md:mt-3 mb-5 md:mb-0">
              <p className="text-xl md:text-base font-medium">
                Ardilla Juli Kristantie
              </p>
              <p className="font-[200] text-lg md:text-sm">
                {t('corsec-personnel')}
              </p>
            </div>
          </div>
          <div>{t('corsec-personnel-bio')}</div>
        </div>
      </div>
      <div className="mt-5 md:mt-10">
        <div className="border-b py-3">
          <h2 className="text-base md:text-2xl font-medium">
            {t('title sekretaris-perusahaan dasar')}
          </h2>
        </div>
        <div className="flex justify-between py-3 md:py-5 px-0 md:px-3">
          <p className="text-sm md:text-base font-[200]">
            {t('corsec-paragraph-1')}
            <br />
            <br />
            <b style={{ fontWeight: 500 }}>{t('dasar hukum')}</b>
            <br />
            Peraturan OJK No. 35/POJK.04/2014 tentang Sekretaris Perusahaan
            Emiten Atau Perusahaan Publik.
            <br />
            <br />
            <b style={{ fontWeight: 500 }}>{t('tugas dan tanggung jawab')}</b>
            <br />
            {t('corsec-paragraph-2')}
            <br />
            <br />
            {ReactHtmlParser(t('corsec-paragraph-3'))}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SectionCorporateSecretary;
